import React, { useEffect, useState, Fragment, useRef } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import reduxActions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import { FieldArray, FormikProvider, useFormik } from "formik";
import _, { initial } from "lodash";
import ProductDropdown from "../../layout/components/ProductDropdown";
import Preview from "../../layout/components/Preview";
import { checkLimits } from "../../plans";
import CustomSelect from "../../layout/components/CustomSelect";
import triggers from "./triggers";
import actions from "./actions";
import { toastMessage } from "../../helpers";
import ConfirmModal from "../modals/ConfirmModal";
import { Dropdown } from "react-bootstrap";
import AlertModal from "../modals/AlertModal";
import UnsavedFormGuard from "../../layout/components/UnsavedFormGuard";

let emptyAction = {
  type: null,
  parameters: {}
};

let emptyTrigger = {
  type: null,
  parameters: {},
  actions: [
    emptyAction
  ]
};

let emptyValues = {
  name: "",
  active: 1,
  products: [],
  triggers: [
    emptyTrigger
  ]
};

let toSubmit = null;

function EditAutomation({ user, products, match, dispatch, fulfillUser }) {
  let [loading, setLoading] = useState(!!match.params.id),
    [automationId, setAutomationId] = useState(match.params.id),
    [product, setProduct] = useState(null),
    [saving, setSaving] = useState(false),
    [showAlert, setShowAlert] = useState(null),
    [previewBody, setPreviewBody] = useState(),
    [previewSettings, setPreviewSettings] = useState({ show: false }),
    [editAction, setEditAction] = useState(null),
    [editTrigger, setEditTrigger] = useState(null),
    [showConfirmRemove, setShowConfirmRemove] = useState(null),
    [addingItem, setAddingItem] = useState(!match.params.id),
    currentIdRef = useRef(),
    history = useHistory(),
    formRef = useRef(),
    formik = useFormik({
      initialValues: emptyValues,
      validate: values => {
        let errors = {};

        if(!values.products || !values.products.length)
          errors.product = "Select a product.";

        for(let i in values.triggers) {
          let trigger = values.triggers[i],
            triggerType = trigger.type,
            triggerParams = triggerType && triggers.byType[triggerType],
            triggerValidation = triggerParams && trigger.parameters && (!trigger._id || editTrigger == trigger._id) && triggerParams.validate(values.triggers[i]);

          if(!_.isEmpty(triggerValidation))
            _.set(errors, "triggers." + i, triggerValidation);

          for(let j in trigger.actions) {
            let action = trigger.actions[j],
              actionType = action.type,
              actionParams = actionType && actions.byType[actionType],
              actionValidation = actionParams && action.parameters && (!action._id || editAction == action._id) && actionParams.validate(values.triggers[i].actions[j]);

            if(!_.isEmpty(actionValidation))
              _.set(errors, "triggers." + i + ".actions." + j, actionValidation);
          }
        }

        return errors;
      },
      onSubmit: data => submitForm(data),
      enableReinitialize: true
    });

  let submitForm = async (data, loading = true) => {
    setSaving(toSubmit || loading);

    let res = automationId
      ? await api.automations.update(automationId, data)
      : await api.automations.create(data);

    if(res && res.success) {
      toastMessage.success("Automation " + (automationId ? "updated!" : "created!"));

      if(res.id)
        setAutomationId(res.id);

      await loadItem(res.id || automationId);
    } else {
      toastMessage.error(res.error || "Unable to save the automation. Please, try again later.");
    }

    setEditAction(null);
    setEditTrigger(null);
    setAddingItem(!hasTriggers(data));
    setSaving(false);
    toSubmit = null;
  };

  let save = async () => {
    await submitForm(formik.values, "modal");
  };

  let hasTriggers = (values = null) => {
    if(!values)
      values = formik.values;

    if(!values.triggers || !values.triggers.length)
      return false;

    for(let trigger of values.triggers) {
      if(trigger.type)
        return true;

      if(trigger.actions && trigger.actions.length)
        for(let action of trigger.actions)
          if(action.type)
            return true;
    }

    return false;
  };

  let loadItem = async (id = null) => {
    if(!id && !match.params.id)
      return setLoading(false);

    let res = await api.automations.get(id || match.params.id);

    if(!res || !res.success || !res.data) {
      toastMessage.error((res && res.error) || "Unable to load the automation details.");
      history.push("/automations", { ignorePrompt: true });
      return;
    }

    setAutomationId(res.data.id);
    setProduct(await api.product.getProduct(res.data.products[0]));
    if(!hasTriggers(res.data))
      res.data.triggers.push(structuredClone(emptyTrigger));

    formik.resetForm({
      values: res.data
    });

    setLoading(false);
  };

  let productOnChange = async selected => {
    formik.setFieldValue("products", selected);
    toSubmit = "product";
    setTimeout(() => formik.handleSubmit());

    setProduct(await api.product.getProduct(selected[0]));
  };

  let actionEditOnClick = (ev, trigger, action) => {
    ev.preventDefault();
    setEditAction(action._id);
  };

  let triggerSelectOnChange = (v, i) => {
    formik.setFieldValue("triggers." + i + ".type", v[0]);
    if(!addingItem && !triggers.byType[v[0]].component) {
      toSubmit = "trigger";
      setTimeout(() => formik.handleSubmit());
    }
  };

  let triggerDeleteOnClick = (ev, fn) => {
    ev.preventDefault();
    setShowConfirmRemove({ what: "trigger", fn });
  };

  let actionDeleteOnClick = (ev, fn) => {
    ev.preventDefault();
    setShowConfirmRemove({ what: "action", fn });
  };

  let triggerEditOnClick = (ev, trigger) => {
    ev.preventDefault();
    setEditTrigger(trigger._id);
  };

  let actionSelectOnChange = (v, i, j) => {
    formik.setFieldValue("triggers." + i + ".actions." + j + ".type", v[0]);
    if(!addingItem && !actions.byType[v[0]].component) {
      toSubmit = "action";
      setTimeout(() => formik.handleSubmit());
    }
  };

  let addTriggerOnClick = fn => {
    setAddingItem(true);
    fn(structuredClone(emptyTrigger));
  };

  let addActionOnClick = fn => {
    setAddingItem(true);
    fn(structuredClone(emptyAction));
  };

  useEffect(() => {
    if(!products || !user)
      return;

    if(!match.params.id)
      checkLimits.canAddAutomation(user);
    else if(match.params.id != currentIdRef.current)
      loadItem();

    currentIdRef.current = match.params.id;
  }, [match.params.id, products, user]);

  return (
    <>
      <h1>
        <Link to="/automations" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        {match.params.id ? "Edit" : "New"} Automation
      </h1>

      {loading
        ? (
          <div className="spinner spinner-full"></div>
        )
        : (
          <form onSubmit={formik.handleSubmit} className={"form-with-preview " + (previewSettings.show ? "" : "preview-hidden")} ref={formRef}>
            <div className="contents">
              <input type="hidden" {...formik.getFieldProps("name")} />
              <input type="hidden" {...formik.getFieldProps("active")} />

              <div className="card p-3 mb-0">
                <ProductDropdown loading={saving == "product"} lg transparent listenersCount={false} noSelectionText="Select Show" subtitle="The trigger on the selected show" value={formik.values.products} onChange={productOnChange} />
              </div>

              <FormikProvider value={formik}>
                <FieldArray
                  name="triggers"
                  render={({ unshift, push, remove, insert }) => (
                    <>
                      <div className={"card-connector " + (formik.values.triggers.length ? "" : "last")}>
                        <button type="button" className="btn-plus" onClick={() => addTriggerOnClick(unshift)} disabled={editTrigger || editAction || addingItem} />
                      </div>

                      {formik.values.triggers.sort((a, b) => a.order - b.order).map((trigger, i) => {
                        let triggerType = trigger.type,
                          triggerParams = triggerType && triggers.byType[triggerType],
                          TriggerForm = triggerParams && triggerParams.component;

                        return (
                          <Fragment key={i}>
                            <div className="card mb-0">
                              {!trigger._id || editTrigger == trigger._id
                                ? (
                                  <>
                                    <div className={"p-3 " + (triggerType ? "border-bottom border-gray" : "")}>
                                      <CustomSelect className="dark-icons" lg value={triggerType} loading={saving == "trigger"} onChange={v => triggerSelectOnChange(v, i)} transparent options={triggers.getOptions()} noSelectionText="Select your trigger event" subtitle="The trigger will fire when the selected event happens" />
                                    </div>

                                    {TriggerForm && (
                                      <div className="p-8 pb-0">
                                        <TriggerForm automationId={automationId} name={"triggers." + i + ".parameters"} formik={formik} product={product} isNew={addingItem} />
                                        {editTrigger && <button type="submit" className={"btn w-full btn-primary " + (saving === true ? "loading spinner" : "")} disabled={formik.touched && !formik.isValid}>Confirm</button>}
                                      </div>
                                    )}
                                  </>
                                )
                                : (
                                  <div className="p-3 border-bottom border-gray">
                                    <div className="trigger-summary">
                                      <SVG src={triggerParams.icon} />
                                      <label className="fill">
                                        {triggerParams.title}<br />
                                        <span className="subtitle">
                                          {triggerParams.getDescription(trigger, { user, products, product })}
                                        </span>
                                      </label>
                                      <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                        <Dropdown.Toggle>
                                          <SVG src={"/media/def-image/icons/menu-2.svg"} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                          <Dropdown.Item href="#" className="text-danger" onClick={ev => triggerDeleteOnClick(ev, () => remove(i))}>
                                            Delete
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#" onClick={ev => triggerEditOnClick(ev, trigger)}>
                                            Edit
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )}

                              {trigger.type && (
                                <div className="p-8 pt-0">
                                  <FieldArray
                                    name={"triggers." + i + ".actions"}
                                    render={({ push: pushAction, remove: removeAction, insert: insertAction, unshift: unshiftAction }) => (
                                      <>
                                        {trigger._id && (
                                          <div className={"actions-connector first " + (trigger.actions.length ? "" : "last")}>
                                            <button type="button" className="btn-plus" disabled={editTrigger || editAction || addingItem} onClick={() => addActionOnClick(unshiftAction)} />
                                          </div>
                                        )}

                                        {trigger.actions.sort((a, b) => a.order - b.order).map((action, j) => {
                                          let actionType = action.type,
                                            actionParams = actionType && actions.byType[actionType],
                                            ActionForm = actionParams && actionParams.component;

                                          return (
                                            <Fragment key={j}>
                                              {!trigger._id || !action._id || !action.type || editAction == action._id
                                                ? (
                                                  <>
                                                    <label className="form-label">What to trigger</label>
                                                    <CustomSelect options={actions.getOptions()} className={"dark-icons " + (actionType ? "mb-10" : "")} loading={saving == "action"} value={actionType} onChange={v => actionSelectOnChange(v, i, j)} noSelectionText="Select an action" />
                                                    {actionType && (
                                                      <>
                                                        {ActionForm && <ActionForm submit={() => formik.handleSubmit()} automationId={automationId} trigger={trigger} triggerIndex={i} product={product} actionIndex={j} isNew={addingItem} saving={saving} remove={remove} name={"triggers." + i + ".actions." + j + ".parameters"} formik={formik} setPreviewBody={setPreviewBody} setPreviewSettings={setPreviewSettings} />}
                                                        <button type="submit" className={"btn w-full btn-primary " + (saving === true ? "loading spinner" : "")} disabled={formik.touched && !formik.isValid}>Confirm</button>
                                                      </>
                                                    )}
                                                  </>
                                                )
                                                : (
                                                  <div className="action-summary">
                                                    <SVG src={actionParams.icon} />
                                                    <div className="fill">
                                                      <label>{actionParams.title}</label>
                                                      <span className="subtitle">
                                                        {actionParams.getDescription(action, { user, products, product })}
                                                      </span>
                                                    </div>
                                                    <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                                      <Dropdown.Toggle>
                                                        <SVG src={"/media/def-image/icons/menu-2.svg"} />
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                                        <Dropdown.Item href="#" className="text-danger" onClick={ev => actionDeleteOnClick(ev, () => removeAction(j))}>
                                                          Delete
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={ev => actionEditOnClick(ev, trigger, action)}>
                                                          Edit
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                )}

                                              {trigger._id && trigger.actions.length && (
                                                <div className={"actions-connector " + (trigger.actions.length - 1 == j ? "last" : "")}>
                                                  <button type="button" className="btn-plus" disabled={editTrigger || editAction || addingItem} onClick={() => addActionOnClick(() => {
                                                    //if(trigger.actions.length - 1 == j)
                                                    //  pushAction(Object.assign({}, emptyAction, { order: trigger.actions.length }));
                                                    //else
                                                    insertAction(j + 1, structuredClone(emptyAction));
                                                  })} />
                                                </div>
                                              )}
                                            </Fragment>
                                          );
                                        })}
                                      </>
                                    )} />
                                </div>
                              )}
                            </div>

                            {formik.values.triggers.length && (
                              <div className={"card-connector " + (formik.values.triggers.length - 1 == i ? "last" : "")}>
                                <button type="button" className="btn-plus" disabled={editTrigger || editAction || addingItem} onClick={() => addActionOnClick(() => {
                                  //if(formik.values.triggers.length - 1 == i)
                                  //  push(Object.assign({}, emptyTrigger, { order: formik.values.triggers.length }));
                                  //else
                                  insert(i + 1, structuredClone(emptyTrigger));
                                })} />
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                    </>
                  )} />
              </FormikProvider>

            </div>

            {previewSettings.show && (
              <Preview title={previewSettings.title} className={previewSettings.className} scroll>
                {previewBody}
              </Preview>
            )}

            <ConfirmModal
              show={!!showConfirmRemove}
              message={showConfirmRemove && "Are you sure you want to remove the " + showConfirmRemove.what + "?"}
              onConfirm={() => {
                showConfirmRemove.fn();
                if(automationId)
                  formik.handleSubmit();
                setShowConfirmRemove(null);
              }}
              onCancel={e => setShowConfirmRemove(null)} />

            <AlertModal
              show={!!showAlert}
              message={showAlert}
              onHide={() => setShowAlert(null)} />
          </form>
        )}

      <UnsavedFormGuard formik={formik} onSaveAsync={save} loading={saving == "modal"} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...reduxActions.product,
      ...reduxActions.auth,
      dispatch
    })
  )(withRouter(EditAutomation))
);
